import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Header from '../../../Nav/header';
import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'
import { useDispatch } from 'react-redux';
import { checkLogin } from '../../../store';

function Meun() {
    const { t } = useTranslation();
    let navigator = useNavigate()
    let dispatch = useDispatch();

    const Logout = async () => {
        if (window.confirm(t("message_8"))) {
            await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/logout', {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.c >= 0 || tmp?.c == -9999) {
                    dispatch(checkLogin(false))
                    localStorage.removeItem("login")
                    navigator('/')
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div className="App-box-1">
            <Header loca={t('menu_0')} type={1} />
            <div className='menu'>
                <Link to={`/menu/profile`} className='menu-1'>
                    <div>{t('menu_1')}</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/settlement`} className='menu-1'>
                    <div>{t('menu_2')}</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/history`} className='menu-1'>
                    <div>{t('menu_3')}</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/transfer`} className='menu-1'>
                    <div>{t('menu_4')}</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/withdraw`} className='menu-1'>
                    <div>{t('menu_5')}</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
                <Link to={`/menu/setting`} className='menu-1'>
                    <div>{t('menu_6')}</div>
                    <div><FontAwesomeIcon icon={faAngleRight} /></div>
                </Link>
            </div>
            <div className='login-3'>
                <div className='login-2-1 btn' onClick={() => Logout()}>{t('menu_7')}</div>
            </div>
            <Footer num={5} />
        </div>
    )
}

export default Meun;