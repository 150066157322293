import '../css/login.css'
import '../css/referrer.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faFileCircleXmark, faCaretDown, faCaretUp, faXmark, faClone } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Decimals } from './common/function'
import { useTranslation } from "react-i18next";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';

function Dashboard() {
    let Typemode = useSelector((state) => { return state.Typemode.value; });
    let Languages = useSelector((state) => { return state.Languages.value; });
    let MyInfo = useSelector((state) => { return state.MyInfo.value; });

    const { t } = useTranslation();
    let dispatch = useDispatch()
    let navigator = useNavigate()

    const [createbtn, setCreateBtn] = useState(false)
    const [refbtn, setRefBtn] = useState(false)
    const [myInfo, setMyInfo] = useState([])
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])
    const [wallet, setWallet] = useState([])
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState("")
    const [settlementDate, setSettlementDate] = useState([])
    const [settlementTodayDate, setSettlementTodaytDate] = useState([])
    const [settlementYesterdayDate, setSettlementYesterdaytDate] = useState([])
    const [settlementLiveDate, setSettlementLivetDate] = useState([])
    const [sum, setSum] = useState([0, 0])

    useEffect(() => {
        DashboardData()
        WalletData()
        SettlementData()
        SettlementTodayData()
        SettlementLiveData()
    }, [])

    useEffect(() => {
        if (settlementDate?.length > 0) {
            let bn = 0
            let okx = 0
            let by = 0
            for (let i = 0; i < settlementDate.length; i++) {
                let tmp = settlementDate[i]
                bn += Number(tmp?.binance_income ?? 0)
                okx += Number(tmp?.okx_income ?? 0)
                by += Number(tmp?.bybit_income ?? 0)
            }
            setSum([bn, okx, by])
        }
    }, [settlementDate])

    const DashboardData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/dashboard/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.c >= 0) {
                setMyInfo(tmp.d.info && tmp.d.info[0] ? tmp.d.info[0] : [])
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const WalletData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/wallet', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.c >= 0) {
                setWallet(tmp.d && tmp.d[0] ? tmp.d[0] : [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSettlementDate(tmp?.d ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementTodayData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement/today', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementTodaytDate(tmp?.d?.today[0] ?? [])
                setSettlementYesterdaytDate(tmp?.d?.yesterday[0] ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementLiveData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement/realtime', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementLivetDate(tmp?.d)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className="App-box">
            <Header loca={`${myInfo?.name ?? ""}`} type={0} />
            {
                createbtn
                    ?
                    <CreateModal setCreateBtn={setCreateBtn} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                refbtn
                    ?
                    <RefModal setRefBtn={setRefBtn} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
            <div className='dashboard-1'>
                <div className='dashboard-1-3'>
                    <div className='dashboard-1-3-1'>{t('dashboard_0')}</div>
                    <CopyToClipboard text={myInfo?.uid ?? " "}>
                        <div className='dashboard-1-3-2' onClick={() => { onChangeModal(); setMessage({ code: 0, msg: t('dashboard_1') }) }}>
                            {`${myInfo?.uid ?? " "}`}
                            {/* <img src='/img/paste_icon.png' style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> */}
                            <i className="fa-regular fa-clone" style={{ marginLeft: "5px", transform: "scaleX(-1)", color: "#757575" }}></i>
                        </div>
                    </CopyToClipboard>
                </div>
                <div className='dashboard-1-4'>
                    <div className='code-btn btn' onClick={() => setRefBtn(true)}>
                        {t('dashboard_24')}
                    </div>
                    <div className='code-btn btn' onClick={() => setCreateBtn(true)}>
                        {t('dashboard_23')}
                    </div>
                </div>
            </div>

            <div className='dashboard-2'>
                <div className='dashboard-2-1'>{t('dashboard_2')}</div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/binance_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>Binance</div>
                            <div className='dashboard-6-1-3'>{Number(wallet?.binance_free ?? 0) + Number(wallet?.binance_lock ?? 0)} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw`)}>{t('dashboard_3')}</div>
                    </div>
                </div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/okx_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>OKX</div>
                            <div className='dashboard-6-1-3'>{Number(wallet?.okx_free ?? 0) + Number(wallet?.okx_lock ?? 0)} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw`)}>{t('dashboard_3')}</div>
                    </div>
                </div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/bybit_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>Bybit</div>
                            <div className='dashboard-6-1-3'>{Number(wallet?.bybit_free ?? 0) + Number(wallet?.bybit_lock ?? 0)} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw`)}>{t('dashboard_3')}</div>
                    </div>
                </div>
            </div>
            <div className='dashboard-2'>
                <div className='dashboard-2-6'>
                    <div className='dashboard-2-1'>{t('dashboard_4')}</div>
                    <div className='dashboard-2-2'>{Decimals(Number(settlementTodayDate?.binance_income ?? 0) + Number(settlementTodayDate?.okx_income ?? 0) + Number(settlementTodayDate?.bybit_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-2-1 red'><FontAwesomeIcon icon={faCaretDown} /> {Decimals(Number(settlementYesterdayDate?.binance_income ?? 0) + Number(settlementYesterdayDate?.okx_income ?? 0) + Number(settlementYesterdayDate?.bybit_income ?? 0)).toFixed(4)}</div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/settlement`)}>{t('dashboard_5')}</div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/binance_logo.png' style={{ width: "25px" }} />Binance</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(settlementTodayDate?.binance_income ?? 0).toFixed(4)} <span>USDT</span></div>
                        <div className='dashboard-2-3-2-2 red'><FontAwesomeIcon icon={faCaretDown} /> {Decimals(settlementYesterdayDate?.binance_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/okx_logo.png' style={{ width: "25px" }} />OKX</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(settlementTodayDate?.okx_income ?? 0).toFixed(4)} <span>USDT</span></div>
                        <div className='dashboard-2-3-2-2 green'><FontAwesomeIcon icon={faCaretUp} /> {Decimals(settlementYesterdayDate?.okx_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/bybit_logo.png' style={{ width: "25px" }} />Bybit</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(settlementTodayDate?.bybit_income ?? 0).toFixed(4)} <span>USDT</span></div>
                        <div className='dashboard-2-3-2-2 green'><FontAwesomeIcon icon={faCaretUp} /> {Decimals(settlementYesterdayDate?.bybit_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='hr-dash'></div>
                <div className='dashboard-2-6'>
                    <div className='dashboard-2-1'>{t('dashboard_6')}</div>
                    <div className='dashboard-2-2' style={{ paddingBottom: "0px", fontSize: "18px" }}>{Decimals(sum[0] ?? 0 + sum[1] ?? 0 + sum[2] ?? 0).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/menu/settlement`)}>{t('dashboard_5')}</div>
                    </div>
                </div>
                <div className='dashboard-2-6' style={{ marginTop: "10px" }}>
                    <div className='dashboard-2-1'>{t('dashboard_7')}</div>
                    <div className='dashboard-2-2' style={{ paddingBottom: "0px", fontSize: "18px" }}>{Decimals(settlementLiveDate?.est_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/menu/history`)}>{t('dashboard_5')}</div>
                    </div>
                </div>
            </div>
            {
                Typemode
                    ?
                    <div className='dashboard-2'>
                        <div className='dashboard-2-1-1'>{t('dashboard_8')}</div>
                        <div className='dashboard-2-4' style={{ paddingRight: "10px" }}>
                            <div className='dashboard-2-4-3 op6'>{t('dashboard_9')}</div>
                            <div className='dashboard-2-4-4 op6'>{t('dashboard_10')}</div>
                            <div className='dashboard-2-4-4 op6'>{t('dashboard_11')}</div>
                        </div>
                        {
                            subR?.length > 0
                                ?
                                subR.map(function (a, i) {
                                    return (
                                        <ReferrerList key={i} item={a} />
                                    )
                                })
                                :
                                <div className='noData'>
                                    <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                </div>

                        }
                    </div>
                    :
                    <div className='dashboard-2'>
                        <div className='dashboard-2-1-1'>{t('dashboard_12')}</div>
                        <div className='dashboard-2-4'>
                            <div className='dashboard-2-4-1 op6'>{t('dashboard_13')}</div>
                            <div className='dashboard-2-4-2 op6'>{t('dashboard_14')}</div>
                            <div className='dashboard-2-4-2-1 op6'>{t('dashboard_15')}</div>
                        </div>
                        {
                            subT?.length > 0
                                ?
                                subT.map(function (a, i) {
                                    return (
                                        <TraderList key={i} item={a} no={i + 1} />
                                    )
                                })
                                :
                                <div className='noData'>
                                    <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                </div>

                        }
                    </div>
            }

            <Footer num={1} />
        </div>
    )
}

function ReferrerList({ item }) {
    return (
        <Link to={`/subReferrer/info/${item.id}`} className='dashboard-2-4' style={{ paddingRight: "10px" }}>
            <div className='sub-arrow-right'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='dashboard-2-4-3'>{item.id}</div>
            <div className='dashboard-2-4-4'>{item.sub_referrer_count}</div>
            <div className='dashboard-2-4-4'>{item.sub_trader_count}</div>
        </Link>
    )
}

function TraderList({ item, no }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{no}</div>
            <div className='dashboard-2-4-2'>{item.id}</div>
            <div className='dashboard-2-4-2-1'>{item.volume}</div>
        </div>
    )
}


function CreateModal({ setCreateBtn, onChangeModal, setMessage }) {
    const { t } = useTranslation();
    const [uid, setUID] = useState("")
    const [otp, setOTP] = useState("")
    const [code, setCode] = useState("")
    const [rate, setRate] = useState("")
    const [state, setState] = useState(false)
    const [inputs, setInputs] = useState(Array(3).fill(Array(2).fill("")));

    const handleInputChange = (rowIndex, colIndex, event) => {
        const { value } = event.target
        const onlyNumber = value.replace(/[^0-9]/g, "")

        const newInputs = inputs.map((row, rIndex) =>
            row.map((value, cIndex) =>
                (rIndex === rowIndex && cIndex === colIndex) ? onlyNumber : value
            )
        );
        setInputs(newInputs);
    };

    const onChangeUID = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUID(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onChangeCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setCode(onlyNumber)
    }
    const onSendOTP = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/regist/referrer', {
            withCredentials: true,
            params: {
                uid: uid
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_1') })
                setState(true)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/regist/referrer/check', {
            withCredentials: true,
            params: {
                uid: uid,
                certifyNumber: otp,
                referrerCode: code,
                rate: Number(rate) / 100
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_2') })
                setTimeout(() => {
                    setCreateBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setCreateBtn(false)}></div>
            <div className='modal-box-1'>
                <div className='modal-1'>
                    <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setCreateBtn(false)} />
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_16')}</div>
                        <div className='modal-2-1-1'>
                            <input value={uid} onChange={onChangeUID} maxLength={6} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_19')}</div>
                        <div className='modal-2-1-1'>
                            <input value={code} onChange={onChangeCode} maxLength={8} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-2'>
                        <div className='modal-1-1-1'>{t('dashboard_20')}(%)</div>
                        <div className='modal-2-2-1'>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1'></div>
                                <div className='modal-2-2-2-1'>Total(%)</div>
                                <div className='modal-2-2-2-1'>ME(%)</div>
                                <div className='modal-2-2-2-1'>KOL(%)</div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Binance</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0]) + Number(inputs[0][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[0][0]} onChange={(event) => handleInputChange(0, 0, event)} />
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[0][1]} onChange={(event) => handleInputChange(0, 1, event)} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>OKX</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0]) + Number(inputs[1][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[1][0]} onChange={(event) => handleInputChange(1, 0, event)} />
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[1][1]} onChange={(event) => handleInputChange(1, 1, event)} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Bybit</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0]) + Number(inputs[2][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[2][0]} onChange={(event) => handleInputChange(2, 0, event)} />
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[2][1]} onChange={(event) => handleInputChange(2, 1, event)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_17')}</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>{t('dashboard_18')}</div>
                        </div>
                    </div>
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmit()}>{t('dashboard_21')}</div>
                </div>
            </div>
        </>
    )
}

function RefModal({ setRefBtn, onChangeModal, setMessage }) {
    const { t } = useTranslation();
    const [uid, setUID] = useState("")
    const [otp, setOTP] = useState("")
    const [code, setCode] = useState("")
    const [rate, setRate] = useState("")
    const [state, setState] = useState(false)
    const [inputs, setInputs] = useState(Array(3).fill(Array(2).fill("")));

    const handleInputChange = (rowIndex, colIndex, event) => {
        const { value } = event.target
        const onlyNumber = value.replace(/[^0-9]/g, "")

        const newInputs = inputs.map((row, rIndex) =>
            row.map((value, cIndex) =>
                (rIndex === rowIndex && cIndex === colIndex) ? onlyNumber : value
            )
        );
        setInputs(newInputs);
    };

    const onChangeUID = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUID(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onChangeCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setCode(onlyNumber)
    }
    const onSendOTP = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/regist/referrer', {
            withCredentials: true,
            params: {
                uid: uid
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_1') })
                setState(true)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/regist/referrer/check', {
            withCredentials: true,
            params: {
                uid: uid,
                certifyNumber: otp,
                referrerCode: code,
                rate: Number(rate) / 100
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_2') })
                setTimeout(() => {
                    setRefBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setRefBtn(false)}></div>
            <div className='modal-box-1'>
                <div className='modal-1'>
                    <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setRefBtn(false)} />
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_25')}</div>
                        <div className='modal-2-1-1'>
                            <input value={uid} onChange={onChangeUID} maxLength={6} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-2'>
                        <div className='modal-1-1-1'>{t('dashboard_20')}(%)</div>
                        <div className='modal-2-2-1'>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1'></div>
                                <div className='modal-2-2-2-1'>Total(%)</div>
                                <div className='modal-2-2-2-1'>ME(%)</div>
                                <div className='modal-2-2-2-1'>KOL(%)</div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Binance</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0]) + Number(inputs[0][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[0][0]} onChange={(event) => handleInputChange(0, 0, event)} />
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[0][1]} onChange={(event) => handleInputChange(0, 1, event)} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>OKX</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0]) + Number(inputs[1][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[1][0]} onChange={(event) => handleInputChange(1, 0, event)} />
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[1][1]} onChange={(event) => handleInputChange(1, 1, event)} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Bybit</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0]) + Number(inputs[2][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[2][0]} onChange={(event) => handleInputChange(2, 0, event)} />
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[2][1]} onChange={(event) => handleInputChange(2, 1, event)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_17')}</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>{t('dashboard_18')}</div>
                        </div>
                    </div>
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmit()}>{t('dashboard_26')}</div>
                </div>
            </div>
        </>
    )
}


export default Dashboard;