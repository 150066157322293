import './nav.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faUser, faCoins, faMoneyCheckDollar, faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Footer({ num }) {
    const { t } = useTranslation();
    return (
        <div className="footer">
            <Link to={`/dashboard`} className={`footer-1 ${num === 1 ? "on" : ""}`}>
                <div className='footer-1-1'><FontAwesomeIcon icon={faHouse} /></div>
                <div className='footer-1-2'>{t('footer_0')}</div>
            </Link>
            <Link to={`/subReferrer`} className={`footer-1 ${num === 2 ? "on" : ""}`}>
                <div className='footer-1-1'><FontAwesomeIcon icon={faUser} /></div>
                <div className='footer-1-2'>{t('footer_1')}</div>
            </Link>
            <Link to={`/settlement`} className={`footer-1 ${num === 3 ? "on" : ""}`}>
                <div className='footer-1-1'><FontAwesomeIcon icon={faCoins} /></div>
                <div className='footer-1-2'>{t('footer_2')}</div>
            </Link>
            <Link to={`/withdraw`} className={`footer-1 ${num === 4 ? "on" : ""}`}>
                <div className='footer-1-1'><FontAwesomeIcon icon={faMoneyCheckDollar} /></div>
                <div className='footer-1-2'>{t('footer_3')}</div>
            </Link>
            <Link to={`/menu`} className={`footer-1 ${num === 5 ? "on" : ""}`}>
                <div className='footer-1-1'><FontAwesomeIcon icon={faBars} /></div>
                <div className='footer-1-2'>{t('footer_4')}</div>
            </Link>
        </div>
    )
}

export default Footer;