import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import { checkLogin } from '../../store';
import { useDispatch } from 'react-redux';

function Withdraw_Network() {
    const { t } = useTranslation();
    let navigator = useNavigate();
    let dispatch = useDispatch();

    let { exchange } = useParams();

    const [networkList, setNetworkList] = useState([])
    const [network, setNetwork] = useState("")
    const [selectNetwork, setSelectNetwork] = useState([])
    const [address, setAddress] = useState("")
    const [tag, setTag] = useState("")

    const onChangeNetwork = (e) => {
        setNetwork(e.target.value);
        let va = networkList.filter((item) => item.network == e.target.value)
        setSelectNetwork(va[0])
    }

    const onChangeAddress = (e) => {
        setAddress(e.target.value);
    }

    const onChangeTag = (e) => {
        setTag(e.target.value);
    }

    useEffect(() => {
        NetworkData()
    }, [])

    const NetworkData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/withdraw/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                let a = tmp.d.data.filter((item) => item.coin == "USDT")
                if (a && a[0]) setNetworkList(a[0].networkList)
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onClickWithdraw = () => {
        if (network != "") {
            navigator(`/withdraw/${exchange}/amount`, {
                state: {
                    ex: exchange,
                    network: selectNetwork,
                    address: address,
                    tag: tag
                }
            })
        }
    }

    return (
        <div className="App-box-1">
            <Link to={`/withdraw`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='withdraw'>
                <div className='withdraw-1-top'>
                    <div className='withdraw-1-top-1'>{t('withdraw_2')}</div>
                    <div className='withdraw-1-top-2'>
                        <img src={exchange == "Binance" ? '/img/binance_logo.png' : exchange == "OKX" ? '/img/okx_logo.png' : '/img/bybit_logo.png'} style={{ width: "25px" }} />
                        <div>{exchange}</div>
                    </div>
                    <div className='withdraw-1-top-3'>
                        <div>{t('withdraw_3')}</div>
                        <div>1,000.00 USDT</div>
                    </div>
                </div>
                <div className='login-1-1'>{t('withdraw_4')}</div>
                <div className='withdraw-1' style={{ marginTop: "20px" }}>
                    <div className='withdraw-1-1'>{t('withdraw_5')}</div>
                    <div className='withdraw-1-2'>
                        <select className='withdraw-1-2-select' value={network} onChange={onChangeNetwork}>
                            <option value={""}>{t('withdraw_6')}</option>
                            {
                                networkList.map((option, index) => (
                                    <option key={index} value={option.network}>{option.network}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className='withdraw-1'>
                    <div className='withdraw-1-1'>{t('withdraw_7')}</div>
                    <div className='withdraw-1-2'>
                        <input className='withdraw-1-2-input' value={address} onChange={onChangeAddress} />
                    </div>
                </div>
                <div className='withdraw-1'>
                    <div className='withdraw-1-1'>{t('withdraw_8')}</div>
                    <div className='withdraw-1-2'>
                        <input className='withdraw-1-2-input' value={tag} onChange={onChangeTag} />
                    </div>
                </div>
                <div className='withdraw-1-btn' onClick={() => { onClickWithdraw() }}>{t('withdraw_9')}</div>
            </div>

            <Footer num={4} />
        </div>
    )
}

export default Withdraw_Network;