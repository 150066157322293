import './css/login.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function SelectType() {
    let navigator = useNavigate()
    return (
        <div className="App-box">
            <Link to={`/authentication`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='login-1'>
                <div className='login-1-1'>사용 타입 선택</div>
                <Link to={`/dashboard`} className='login-1-2 btn'>레퍼럴 영업</Link>
                <Link to={`/dashboard`} className='login-1-2 btn'>트레이더 영업</Link>
            </div>
        </div>
    )
}

export default SelectType;