import './css/login.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import Header from '../Nav/header';

function Login() {
    const { t } = useTranslation();
    let navigate = useNavigate()
    const [number, setNumber] = useState("")
    const [otp, setOTP] = useState("")
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState({ code: 0, msg: "" })

    const onChangeNumber = (e) => {
        setNumber(e.target.value);
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            Check_Authentication()
        };
    }

    useEffect(() => {
        let obj = localStorage.getItem("login")
        if (obj) {
            navigate(`/dashboard`)
        }
    }, [])

    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const Check_Mobile = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/login', {
            withCredentials: true,
            params: {
                id: number,
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                onChangeModal();
                setMessage({ code: 0, msg: t('message_0') })
                // navigate(`/authentication`, {
                //     state: {
                //         id: number.startsWith('0') ? number.substring(1) : number
                //     }
                // })
                // Check_Authentication()
            }
            else {
                onChangeModal();
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const Check_Authentication = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/login/check', {
            withCredentials: true,
            params: {
                id: number,
                certifyNumber: otp
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                navigate(`/dashboard`)
                localStorage.setItem("login", true)
            }
            else {
                alert(tmp.m)
            }
        }).catch(error => {
            console.error(error);
        });

    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className="App-box-1">
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
            <Header loca={""} type={2} />
            <div className='login-1'>
                <img src='/img/namu_light.png' style={{ paddingBottom: "20px" }} />
                <div className='login-1-1' style={{ textAlign: "left" }}>{t('login_0')}</div>
                <div className='login-otp'>
                    <input className='login-1-2-input' placeholder={`${t('login_1')}`} type="text" value={number} onChange={onChangeNumber} ref={inputRef} />
                    <div className='loing-btn' onClick={() => Check_Mobile()}>{`${t('login_3')}`}</div>
                </div>
                <input className='login-1-1-input' placeholder={`${t('login_2')}`} type="number" value={otp} onChange={onChangeOTP} onKeyDown={handleKeyPress} />
            </div>

            <div className='login-2'>
                <div className='login-2-1 btn' onClick={() => Check_Authentication()}>{`${t('login_5')}`}</div>
            </div>
        </div>
    )
}

export default Login;