import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCaretDown, faCaretUp, faAngleRight, faFileCircleXmark, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { Decimals } from './common/function'
import { useTranslation } from "react-i18next";

import moment from 'moment';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import { checkLogin } from '../../store';
import { useDispatch } from 'react-redux';

function SubReferrer_Info() {
    const { t } = useTranslation();
    
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const [tab, setTab] = useState(false)
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])
    const [sum, setSum] = useState([0, 0, 0])
    const [refBtn, setRefBtn] = useState(true)
    const [traderBtn, setTraderBtn] = useState(true)

    const [settlementData, setSettlementData] = useState([])
    const [settlementList, setSettlementList] = useState([])
    const [settlementTodayData, setSettlementTodaytData] = useState([])
    const [settlementMonthData, setSettlementMonthtData] = useState([])

    let { broker } = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023; // 시작 년도를 2023으로 설정
        const yearsArray = [];

        for (let i = startYear; i <= currentYear; i++) {
            yearsArray.push(i);
        }

        setYears(yearsArray);
    }, []);

    useEffect(() => {
        setTab(false)
        SubReferrerData()
        SettlementData()
    }, [broker])

    const SubReferrerData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/sub/info', {
            withCredentials: true,
            params: {
                subId: broker
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement', {
            withCredentials: true,
            params: {
                id: broker
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSettlementData(tmp?.d)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (settlementData?.length > 0) {
            let bn = 0
            let okx = 0
            let by = 0
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                bn += Number(tmp.binance_income)
                okx += Number(tmp.okx_income)
                by += Number(tmp.bybit_income)
            }
            setSum([bn, okx, by])
        }
    }, [settlementData])

    useEffect(() => {
        if (settlementData?.length > 0) {
            let sumMonth = 0
            let arr = []
            for (let i = 0; i < settlementData.length; i++) {
                let tmp = settlementData[i]
                if (`${Number(tmp.fill_date.split('-')[0])}-${Number(tmp.fill_date.split('-')[1])}` == `${selectedYear}-${selectedMonth}`) {
                    arr.push(tmp)
                    sumMonth += Number(tmp.binance_income) + Number(tmp.okx_income) + Number(tmp.bybit_income)
                }
                if (tmp.fill_date.split(' ')[0] === moment(new Date()).format("YYYY-MM-DD")) {
                    setSettlementTodaytData(tmp)
                }
            }
            setSettlementList(arr)
            setSettlementMonthtData(sumMonth)
        }
    }, [settlementData, selectedYear, selectedMonth])

    const [traderInput, setTraderInput] = useState("")
    const [refInput, setRefInput] = useState("")

    const onChangeTraderInput = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        setTraderInput(onlyNumber);
    }

    const onChangeRefInput = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        setRefInput(onlyNumber);
    }

    let filter_subR = [];
    let filter_subT = [];
    filter_subR = subR.filter((p) => {
        return (String(p.id).toLocaleLowerCase().includes(refInput.toLocaleLowerCase()))
    })

    filter_subT = subT.filter((p) => {
        return (String(p.id).toLocaleLowerCase().includes(traderInput.toLocaleLowerCase()))
    })

    return (
        <div className="App-box">
            <div className='header'>
                <div className='header-left'>
                    <div onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </div>
                </div>
                <div className='header-center'>
                    <div>{broker}</div>
                </div>
            </div>
            <div className='sub-1'>
                <div className={`sub-1-2 ${tab ? "" : "on"}`} onClick={() => setTab(false)}>
                    <div className='sub-1-1-1'>{t('subRef_22')}</div>
                </div>
                <div className={`sub-1-2 ${tab ? "on" : ""}`} onClick={() => setTab(true)}>
                    <div className='sub-1-1-1'>{t('subRef_23')}</div>
                </div>
            </div>
            {
                tab
                    ?
                    <>
                        <div className='sub-2'>
                            <div className='sub-2-2'>
                                <div>{t('subRef_1')}</div>
                                <div className='sub-2-2-1'>
                                    <input className='sub-2-2-1-input' value={refInput} onChange={onChangeRefInput} placeholder={t('subRef_3')} />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                            </div>
                            <div onClick={() => setRefBtn(e => !e)}>{filter_subR?.length ?? 0} <FontAwesomeIcon icon={refBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            refBtn
                                ?
                                filter_subR?.length > 0
                                    ?
                                    filter_subR.map(function (a, i) {
                                        return (
                                            <ReferrerList key={i} item={a} />
                                        )
                                    })
                                    :
                                    <div className='dashboard-2'>
                                        <div className='noData'>
                                            <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                        </div>
                                    </div>
                                :
                                ""
                        }
                        <div className='sub-2'>
                            <div className='sub-2-2'>
                                <div>{t('subRef_2')}</div>
                                <div className='sub-2-2-1'>
                                    <input className='sub-2-2-1-input' value={traderInput} onChange={onChangeTraderInput} placeholder={t('subRef_3')} />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                            </div>
                            <div onClick={() => setTraderBtn(e => !e)}>{filter_subT?.length ?? 0} <FontAwesomeIcon icon={traderBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            traderBtn
                                ?
                                <div className='dashboard-2'>
                                    <div className='dashboard-2-4'>
                                        <div className='dashboard-2-4-1'>{t('subRef_4')}</div>
                                        <div className='dashboard-2-4-2'>{t('subRef_5')}</div>
                                        <div className='dashboard-2-4-2-1'>{t('subRef_6')}</div>
                                    </div>
                                    {
                                        filter_subT?.length > 0
                                            ?
                                            filter_subT.map(function (a, i) {
                                                return (
                                                    <ListItem key={i} item={a} />
                                                )
                                            })
                                            :
                                            <div className='noData'>
                                                <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                            </div>
                                    }
                                </div>
                                :
                                ""
                        }
                    </>
                    :
                    <>
                        <div className='dashboard-3'>
                            <div className='dashboard-3-1'>
                                <div className='dashboard-3-1-1'>{t('subRef_24')}</div>
                                <div className='dashboard-3-1-2'>{Decimals(Number(settlementTodayData?.binance_income ?? 0) + Number(settlementTodayData?.okx_income ?? 0) + Number(settlementTodayData?.bybit_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                                <div className='dashboard-3-1-3'>
                                    <div className='dashboard-3-1-3-1'><img src='/img/binance_logo.png' className='logo-img' />Binance</div>
                                    <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.binance_income ?? 0).toFixed(4)} </div>
                                </div>
                                <div className='dashboard-3-1-3'>
                                    <div className='dashboard-3-1-3-1'><img src='/img/okx_logo.png' className='logo-img' />OKX</div>
                                    <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.okx_income ?? 0).toFixed(4)} </div>
                                </div>
                                <div className='dashboard-3-1-3'>
                                    <div className='dashboard-3-1-3-1'><img src='/img/bybit_logo.png' className='logo-img' />Bybit</div>
                                    <div className='dashboard-3-1-3-2'>{Decimals(settlementTodayData?.bybit_income ?? 0).toFixed(4)} </div>
                                </div>
                            </div>
                            <div className='dashboard-3-2'>
                                <div className='dashboard-3-2-1'>
                                    <div className='dashboard-3-2-1-1'>{t('subRef_25')}</div>
                                    <div className='dashboard-3-2-1-2'>{Decimals(Number(sum[0] ?? 0) + Number(sum[1] ?? 0) + Number(sum[2] ?? 0)).toFixed(4)} <span>USDT</span></div>
                                </div>
                                <div className='dashboard-3-2-1'>
                                    <div className='dashboard-3-2-1-1'>{t('subRef_26')}</div>
                                    <div className='dashboard-3-2-1-2'>{Decimals(settlementMonthData ?? 0).toFixed(4)} <span>USDT</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-2'>
                            <div className='menu-2-2' style={{ marginTop: "0px" }}>
                                <select
                                    id="year"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(Number(e.target.value))}
                                >
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    id="month"
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                >
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='menu-2-3'>
                                <div className='menu-2-3-1'>{t('subRef_27')}</div>
                                <div className='menu-2-3-2'>{t('subRef_28')}</div>
                                <div className='menu-2-3-3'>{t('subRef_29')}</div>
                            </div>
                            {
                                settlementList?.length > 0
                                    ?
                                    settlementList.map(function (a, i) {
                                        return (
                                            <SettlementItem key={i} item={a} />
                                        )
                                    })
                                    :
                                    <div className='noData'>
                                        <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                    </div>
                            }
                        </div>
                    </>
            }

            <Footer num={2} />
        </div>
    )
}

function SettlementItem({ item }) {
    let formatDate = item.fill_date?.split(' ')

    return (
        <div className='menu-2-4-item'>
            <div className='menu-2-3-1'>{formatDate[0]}</div>
            <div className='menu-2-3-2'>{(Number(item.binance_income) + Number(item.okx_income) + Number(item.bybit_income)).toFixed(8)}</div>
            <div className='menu-2-3-3'>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>Binance</div>
                    <div className='menu-2-3-3-2'>{Number(item.binance_income).toFixed(8)}</div>
                </div>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>OKX</div>
                    <div className='menu-2-3-3-2'>{Number(item.okx_income).toFixed(8)}</div>
                </div>
                <div className='menu-2-3-3-1'>
                    <div className='menu-2-3-3-2'>Bybit</div>
                    <div className='menu-2-3-3-2'>{Number(item.bybit_income).toFixed(8)}</div>
                </div>
            </div>
        </div>
    )
}

function ReferrerList({ item }) {
    const { t } = useTranslation();
    return (
        <Link to={`/subReferrer/info/${item.id}`} state={{ broker: item.id }} className='dashboard-2' >
            <div className='sub-arrow-right-1'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='sub-3'>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>{t('subRef_7')}</div>
                    <div>{item.id}</div>
                </div>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>{t('subRef_8')}</div>
                    <div>{item.sub_broker_count}</div>
                </div>
                <div className='sub-3-1'>
                    <div className='sub-3-1-1'>{t('subRef_9')}</div>
                    <div>{item.sub_trader_count}</div>
                </div>
            </div>
        </Link >
    )
}

function ListItem({ item }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{item.id}</div>
            <div className='dashboard-2-4-2'>{Number(item.volume).toFixed(3)}</div>
            <div className='dashboard-2-4-2-1'>{item.regist_date}</div>
        </div>
    )
}

export default SubReferrer_Info;