import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileCircleXmark } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'
import { checkLogin } from '../../../store';
import { useDispatch } from 'react-redux';

function Menu_Transfer() {
    const { t } = useTranslation();
    let navigator = useNavigate()
    let dispatch = useDispatch();
    
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const [withdrawDate, setWithdrawDate] = useState([])

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023; // 시작 년도를 2023으로 설정
        const yearsArray = [];

        for (let i = startYear; i <= currentYear; i++) {
            yearsArray.push(i);
        }

        setYears(yearsArray);
    }, []);

    useEffect(() => {
        WithdrawData()
    }, [])

    const WithdrawData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/transfer/history', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setWithdrawDate(tmp.d)
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>{t('menu_35')}</div>
                </div>
            </div>
            <div className='menu-2'>
                {/* <div className='menu-2-2'>
                    <select
                        id="year"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}년
                            </option>
                        ))}
                    </select>

                    <select
                        id="month"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(Number(e.target.value))}
                    >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                            <option key={month} value={month}>
                                {month}월
                            </option>
                        ))}
                    </select>
                </div> */}
                <div className='menu-2-4'>
                    <div className='menu-2-4-1'>{t('menu_36')}</div>
                    <div className='menu-2-4-1'>{t('menu_37')}</div>
                    <div className='menu-2-4-1'>{t('menu_38')}</div>
                    <div className='menu-2-4-1'>{t('menu_39')}</div>
                </div>
                {
                    withdrawDate?.length > 0
                        ?
                        withdrawDate.map(function (a, i) {
                            return (
                                <WithdrawItem key={i} item={a} />
                            )
                        })
                        :
                        <div className='noData'>
                            <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                        </div>

                }
            </div>

            <Footer num={5} />
        </div>
    )
}

function WithdrawItem({ item }) {
    return (
        <div className='menu-2-3-item'>
            <div className='menu-2-4-1'>{item.time}</div>
            <div className='menu-2-4-1'>{item.receiver}</div>
            <div className='menu-2-4-1'>{item.amt}</div>
            <div className='menu-2-4-1'>{item.ex}</div>
        </div>
    )
}

export default Menu_Transfer;