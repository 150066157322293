import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Footer from '../../../Nav/footer';

import '../../css/login.css'
import '../../css/referrer.css'
import { checkLogin } from '../../../store';
import { useDispatch } from 'react-redux';

function Menu_Profile() {
    const { t } = useTranslation();
    let navigator = useNavigate()
    let dispatch = useDispatch();

    const [myInfo, setMyInfo] = useState([])

    useEffect(() => {
        DashboardData()
    }, [])

    const DashboardData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/menu/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setMyInfo(tmp.d && tmp.d[0] ? tmp.d[0] : [])
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    let formatDate = myInfo?.regist_date?.split('T') ?? ["", ""]
    let formatDate1 = formatDate[1].split('.000Z') ?? ["", ""]

    return (
        <div className="App-box-1">
            <div className='header white'>
                <div className='header-left'>
                    <Link to={`/menu`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
                    </Link>
                </div>
                <div className='header-center'>
                    <div>{t('menu_8')}</div>
                </div>
            </div>
            <div className='menu-2' style={{ paddingTop: "50px" }}>
                <div className='menu-2-1'>
                    <div className='bold'>{t('menu_9')}</div>
                    <div>{myInfo?.uid ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>{t('menu_10')}</div>
                    <div>{myInfo?.name ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>{t('menu_11')}</div>
                    <div>{myInfo?.email ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>{t('menu_12')}</div>
                    <div>{myInfo?.mobile ?? ""}</div>
                </div>
                <div className='menu-2-1'>
                    <div className='bold'>{t('menu_13')}</div>
                    <div>{formatDate[0]} {formatDate1[0]}</div>
                </div>
            </div>

            <Footer num={5} />
        </div>
    )
}

export default Menu_Profile;