import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import { checkLogin } from '../../store';
import { useDispatch } from 'react-redux';

function Withdraw_Amount() {
    const { t } = useTranslation();
    let navigator = useNavigate();
    const location = useLocation();
    let dispatch = useDispatch();

    const [amount, setAmount] = useState("")
    const [ex, setEx] = useState(location.state === null ? "" : location.state.ex)
    const [network, setNetwork] = useState(location.state === null ? "" : location.state.network)
    const [address, setAddress] = useState(location.state === null ? "" : location.state.address)
    const [tag, setTag] = useState(location.state === null ? "" : location.state.tag)

    useEffect(() => {
        if (location.state === null) {
            navigator(`/withdraw`)
        }
    }, [location])

    const onChangeAmount = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setAmount(onlyNumber);
    }

    const onClickWithdraw = () => {
        if (ex == "Binance") {
            // Binance_Withdraw()
        }
        else {

        }
        navigator(`/withdraw`)
    }

    const Binance_Withdraw = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/withdraw/binance', {
            withCredentials: true,
            network: network,
            address: address,
            addressTag: tag,
            amount: amount
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.c >= 0) {
                navigator(`/withdraw`)
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="App-box-1">
            <Link to={`/withdraw`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='withdraw'>
                <div className='withdraw-1-top'>
                    <div className='withdraw-1-top-1'>{t('withdraw_10')}</div> 
                    <div className='withdraw-1-top-2'>
                        <img src={ex == "Binance" ? '/img/binance_logo.png' : ex == "OKX" ? '/img/okx_logo.png' : '/img/bybit_logo.png'} style={{ width: "25px" }} />
                        <div>{ex}</div>
                    </div>
                    <div className='withdraw-1-top-3'>
                        <div>{t('withdraw_3')}</div>
                        <div>1,000.00 USDT</div>
                    </div>
                </div>
                <div className='login-1-1'>{t('withdraw_12')}</div>
                <div className='withdraw-2' style={{ marginTop: "20px" }}>
                    <div className='withdraw-1-5'>
                        <input className='withdraw-1-2-input' value={amount} onChange={onChangeAmount} />
                    </div>
                    <div className='withdraw-1-6'>USDT</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_13')}</div>
                    <div className='withdraw-1-4'>{ex}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_14')}</div>
                    <div className='withdraw-1-4'>{network.network}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_15')}</div>
                    <div className='withdraw-1-4'>{address}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_16')}</div>
                    <div className='withdraw-1-4'>{tag}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_17')}</div>
                    <div className='withdraw-1-4'>{network?.withdrawMin??0}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_18')}</div>
                    <div className='withdraw-1-4'>{network?.withdrawFee??0}</div>
                </div>
                <div className='withdraw-1-btn' onClick={() => onClickWithdraw()}>{t('withdraw_19')}</div>
            </div>

            <Footer num={4} />
        </div>
    )
}

export default Withdraw_Amount;