import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'

function Withdraw() {
    const { t } = useTranslation();
    return (
        <div className="App-box-1">
            <Header loca={t('withdraw_0')} type={1} />

            <Link to={`/authentication`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='login-4'>
                <div className='login-1-1'>{t('withdraw_1')}</div>
                <div className='login-1-3'>
                    <Link to={`/withdraw/Binance`} className='login-1-3-1 btn'>
                        <div className='login-1-3-1-box'>
                            <img src='/img/binance_logo.png' />
                            <div>Binance</div>
                        </div>
                    </Link>
                    <Link to={`/withdraw/OKX`} className='login-1-3-1 btn'>
                        <div className='login-1-3-1-box'>
                            <img src='/img/okx_logo.png' />
                            <div>OKX</div>
                        </div>
                    </Link>
                    <Link to={`/withdraw/Bybit`} className='login-1-3-1 btn'>
                        <div className='login-1-3-1-box'>
                            <img src='/img/bybit_logo.png' />
                            <div>Bybit</div>
                        </div>
                    </Link>
                </div>
            </div>

            <Footer num={4} />
        </div>
    )
}

export default Withdraw;